<template>
  <ExtendedView
    v-if="false"
    :tabs="tabs"
  >
    <template v-slot:list="{ item }">
      <WidgetList
        v-if="item.key === 'store-widgets'"
        :load-items-callback="getStoreWidgets"
        :call-to-actions="storeWidgetCallToActions"
        @selectWidget="selectWidget"
      />
      <WidgetList
        v-if="item.key === 'schedule-widgets'"
        :load-items-callback="getScheduleWidgets"
        @selectWidget="selectWidget"
      />
    </template>
    <template v-slot:profile>
      <WidgetProfile :widget="selectedWidget" />
    </template>
  </ExtendedView>
</template>

<script>
import ExtendedView from "@/components/common/ExtendedView"
import WidgetList from "@/components/widget/WidgetList"
import WidgetProfile from "@/components/widget/WidgetProfile"

export default {
  name: "Widgets",
  components: {
    WidgetProfile,
    WidgetList,
    ExtendedView
  },
  data() {
    return {
      scheduleWidgets: [],
      storeWidgets: [],
      selectedWidget: null
    }
  },
  computed: {
    scheduleWidgetsCount() {
      if(Array.isArray(this.scheduleWidgets)) {
        return this.scheduleWidgets.length
      }
      return 0
    },
    storeWidgetsCount() {
      if(Array.isArray(this.storeWidgets)) {
        return this.storeWidgets.length
      }
      return 0
    },
    storeWidgetCallToActions() {
      return [
        {
          title: this.$t('add'),
          callback: this.addNewStoreWidget
        }
      ]
    },
    tabs() {
      return [
        {
          title: 'Store Widgets',
          key: 'store-widgets',
          link: '/widgets/store-widgets',
          badge: {
            content: this.storeWidgetsCount
          }
        },
        {
          title: 'Schedule Widgets',
          key: 'schedule-widgets',
          link: '/widgets/schedule-widgets',
          badge: {
            content: this.scheduleWidgetsCount
          }
        }
      ]
    }
  },
  methods: {
    selectWidget(widget) {
      this.selectedWidget = widget
    },
    getStoreWidgets() {
      return this.$store.dispatch('getStoreWidgets').then(result => {
        this.storeWidgets = result
        this.$store.commit('updateAppBarTabs', this.tabs)
        return result
      })
    },
    getScheduleWidgets() {
      return this.$store.dispatch('getScheduleWidgets').then(result => {
        this.scheduleWidgets = result
        this.$store.commit('updateAppBarTabs', this.tabs)
        return result
      })
    }
  },
  created() {
    this.$store.commit('updateAppBarTabs', this.tabs)

    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
    this.$store.commit('setMinimizeSidebar', false)
  },
  updated() {
    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
    this.$store.commit('setMinimizeSidebar', false)
  },
  destroyed() {
    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', false)
    this.$store.commit('setMinimizeSidebar', true)
  }
}
</script>

<style scoped>

</style>